(function () {
    'use strict';
 
    angular
       .module('lariAidApp')
       .config(stateConfig);
    stateConfig.$inject = ['$stateProvider', 'APP'];
 
    function stateConfig($stateProvider, APP) {
       $stateProvider.state('country-admin', {
          parent: 'users',
          url: '/country-admin',
          data: {
             authorities: [APP.GLOBAL.ROLE_MANAGER]
          },
          params: {
             id: null,
          },
          views: {
             'userList': {
                templateUrl: 'app/user/admin/admins.html',
                controller: 'CountryAdminController',
                controllerAs: 'vm'
             }
          },
          resolve: {
             mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                if (localStorage && localStorage.getItem('lari_currentVersion') != null) {
                   $translatePartialLoader.addPart('user.json?version=' + localStorage.getItem('lari_currentVersion'));
                } else {
                   $translatePartialLoader.addPart('user.json?timestamp=' + new Date().getTime());
                }
                return $translate.refresh();
             }],
             mdTabIndex: ['tabservice', '$timeout', function (tabservice, $timeout) {
                tabservice.setIndex("3");
                $timeout(function () {
                   angular.element('#countryAdmin').triggerHandler('click');
                });
             }]
          }
       });
    }
 })();